import { Observable } from 'rxjs';
import { ACTIVITY_TYPES } from 'state-domains/constants';
import { RowReferenceResponse } from 'state-domains/domain/drillhole';
import { buildDuplicateSamplesUrl, buildDuplicateSamplesPointsUrl } from 'state-domains/network';

import { getRowReferenceWithNextRowsResponseObservable } from './common';

export const addDuplicateSample = (
    rowReference: any,
    parent: string,
    type: ACTIVITY_TYPES,
): Observable<RowReferenceResponse> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildDuplicateSamplesUrl(rowReference.values, parent)
            : buildDuplicateSamplesPointsUrl(rowReference.values, parent);
    return getRowReferenceWithNextRowsResponseObservable(url, rowReference, 'POST');
};
